import { useState, createContext, useContext, useCallback, useEffect, useMemo } from 'react'
import { Auth, AuthResponse, LoginRequest, ResetPasswordRequest, UsernameAuthBaseRequest, Verify2FARequest } from '../../clients/api/auth'
import { useConfig } from '..'
import { useLocalStorage } from 'usehooks-ts'
import { ICreateAccountPropsBase } from '../../components/forms/createAccountForm/Model'
import ForgotPasswordFormProps from '../../components/forms/forgotPasswordForm/Model'
import ResetPasswordFormProps from '../../components/forms/resetPasswordForm/Model'
import { ChangePasswordRequest, EmptyResponse, LogoutRequest, NewPasswordRequest, Setup2FAResponse, UserResponse } from '../../clients/api/auth/Model'
import ChangePasswordFormProps from '../../components/forms/changePasswordForm/Model'
import { ConfigStateEnum } from '../config/Model'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthProvider = (props: any): JSX.Element => {
    const [initialized, setInitialized] = useState(false)
    const [userStored, setUserStored] = useLocalStorage('userStored', '')
    const [redirectToAppCount, setRedirectCount] = useLocalStorage('redirectToAppCount', 0)
    const [rememberMe, setRememberMeStored] = useLocalStorage('rememberMe', false)
    const [username, setUsername] = useState(userStored)
    const [loading, setLoading] = useState(true)

    const [authApi, setAuthApi] = useState(new Auth('', '', 0, false))

    const { loadingEnvConfig, envConfig } = useConfig()

    useEffect(() => {
        if (loadingEnvConfig !== ConfigStateEnum.Loaded || initialized) return

        const baseUrl = envConfig.AuthAPI.BaseUrl
        const credentials = envConfig.AuthAPI.CredentialsHeader
        const requestTimeout = envConfig.AuthAPI.requestTimeout
        const username2Lowercase = envConfig.AuthAPI.username2Lowercase ?? false

        if (!initialized && baseUrl !== '' && credentials !== '' && requestTimeout > 0) {
            setInitialized(true)
            const authApiInstance = new Auth(baseUrl, credentials, requestTimeout, username2Lowercase)
            setAuthApi(authApiInstance)
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            authApiInstance.Start()
            setLoading(false)
        }
    }, [
        authApi,
        envConfig.AuthAPI.BaseUrl,
        envConfig.AuthAPI.CredentialsHeader,
        envConfig.AuthAPI.requestTimeout,
        initialized,
        loadingEnvConfig,
        envConfig.AuthAPI.username2Lowercase,
    ])

    const signIn = useCallback(
        async (mail: string, password: string): Promise<AuthResponse> => {
            setLoading(true)
            const req = new LoginRequest(mail, password)
            try {
                return await authApi.Login(req)
            } finally {
                setLoading(false)
            }
        },
        [authApi]
    )

    const signOut = useCallback(
        async (token: string): Promise<EmptyResponse> => {
            setLoading(true)
            const req = new LogoutRequest(token)
            try {
                return await authApi.Logout(req)
            } finally {
                setLoading(false)
            }
        },
        [authApi]
    )

    const getUser = useCallback(
        async (username: string): Promise<UserResponse> => {
            setLoading(true)
            const req = new UsernameAuthBaseRequest(username)
            try {
                // eslint-disable-next-line @typescript-eslint/return-await
                return await authApi.GetUser(req)
            } finally {
                setLoading(false)
            }
        },
        [authApi]
    )

    const newPassword = useCallback(
        async (username: string, passwordNew: string, confirmedPasswordNew: string): Promise<EmptyResponse> => {
            setLoading(true)
            const req = new NewPasswordRequest(username, passwordNew, confirmedPasswordNew)
            try {
                return await authApi.NewPassword(req)
            } finally {
                setLoading(false)
            }
        },
        [authApi]
    )

    const setup2FA = useCallback(async (): Promise<Setup2FAResponse> => {
        setLoading(true)

        try {
            const req = new UsernameAuthBaseRequest(username)
            return await authApi.Setup2FA(req)
        } finally {
            setLoading(false)
        }
    }, [authApi, username])

    const verify2FA = useCallback(
        async (code: string): Promise<EmptyResponse> => {
            setLoading(true)

            try {
                const req = new Verify2FARequest(username, code)
                return await authApi.Verify2FA(req)
            } finally {
                setLoading(false)
            }
        },
        [authApi, username]
    )

    const verifyAfterLogin2FA = useCallback(
        async (code: string): Promise<EmptyResponse> => {
            setLoading(true)

            try {
                const req = new Verify2FARequest(username, code)
                return await authApi.VerifyAfterLogin2FA(req)
            } finally {
                setLoading(false)
            }
        },
        [authApi, username]
    )

    const createAccount = useCallback(
        async (accountData: ICreateAccountPropsBase): Promise<EmptyResponse> => {
            setLoading(true)

            try {
                return await authApi.Register(accountData)
            } finally {
                setLoading(false)
            }
        },
        [authApi]
    )

    const resetPassword = useCallback(
        async (resetPasswordData: ResetPasswordFormProps): Promise<EmptyResponse> => {
            const { user, recoveryCode, password } = resetPasswordData
            setLoading(true)
            try {
                return await authApi.ResetPassword(new ResetPasswordRequest(user, password, recoveryCode))
            } finally {
                setLoading(false)
            }
        },
        [authApi]
    )

    const changePassword = useCallback(
        async (changePasswordData: ChangePasswordFormProps): Promise<EmptyResponse> => {
            const { user, password, passwordNew, passwordNewConfirm, code } = changePasswordData
            setLoading(true)
            try {
                return await authApi.ChangePassword(new ChangePasswordRequest(user, password, passwordNew, passwordNewConfirm, code))
            } finally {
                setLoading(false)
            }
        },
        [authApi]
    )

    const forgotPassword = useCallback(
        async (forgotPasswordData: ForgotPasswordFormProps): Promise<EmptyResponse> => {
            setLoading(true)

            try {
                return await authApi.ForgotPassword(new UsernameAuthBaseRequest(forgotPasswordData.email))
            } finally {
                setLoading(false)
            }
        },
        [authApi]
    )

    const storeUsername = useCallback(
        async (usernameNew: string) => {
            setUsername(usernameNew)
            setUserStored(rememberMe ? usernameNew : '')
        },
        [rememberMe, setUserStored]
    )

    const initializeRedirectToAppCount = useCallback(() => {
        setRedirectCount(0)
    }, [setRedirectCount])

    const incrementRedirectToAppCount = useCallback(() => {
        setRedirectCount(redirectToAppCount + 1)
    }, [redirectToAppCount, setRedirectCount])

    const storeRememberMe = useCallback(
        async (rememberMe: boolean) => {
            setUserStored(rememberMe ? username : '')
            setRememberMeStored(rememberMe)
        },
        [setRememberMeStored, setUserStored, username]
    )

    const authContextValue = useMemo(
        () => ({
            username,
            rememberMe,
            loading,
            redirectToAppCount,
            signIn,
            signOut,
            getUser,
            newPassword,
            setup2FA,
            verify2FA,
            verifyAfterLogin2FA,
            createAccount,
            forgotPassword,
            resetPassword,
            changePassword,
            storeRememberMe,
            storeUsername,
            initializeRedirectToAppCount,
            incrementRedirectToAppCount,
        }),
        [
            username,
            rememberMe,
            loading,
            redirectToAppCount,
            signIn,
            signOut,
            getUser,
            newPassword,
            setup2FA,
            verify2FA,
            verifyAfterLogin2FA,
            createAccount,
            forgotPassword,
            resetPassword,
            changePassword,
            storeRememberMe,
            storeUsername,
            initializeRedirectToAppCount,
            incrementRedirectToAppCount,
        ]
    )

    const contextValue = useMemo(() => (loadingEnvConfig !== ConfigStateEnum.Loaded ? AuthContextInitial : authContextValue), [authContextValue, loadingEnvConfig])

    return <AuthContext.Provider value={contextValue} {...props} />
}

const AuthContextInitial = { username: '', rememberMe: false, loading: true, redirectToAppCount: 0 }

const AuthContext = createContext(AuthContextInitial)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useAuth = (): any => useContext(AuthContext)

export { AuthProvider, useAuth }

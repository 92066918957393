import { useCallback, useMemo, useRef, useState } from 'react'
import { ProductTypeEnum, ProfileTypeEnum } from '../Model'
import { CreateAccountBusinessProCognitoDevProps } from './Model'
import { SingleCard } from '../../../common'
import { useConfig } from '../../../../contexts'
import GeneralInformationForm from '../tabs/GeneralInformationTab'
import PersonalDetailsForm from '../tabs/PersonalDetailsTab'
import AcknowledgementForm from '../tabs/AcknowledgementTab'
import CreateAccountFormIndividualStandard from '../Form/IndividualStandard/Form'

const CreateAccountFormCognitoDev = (): JSX.Element => {
    const { commonConfig } = useConfig()

    const [profileType, setProfileType] = useState(ProfileTypeEnum.Individual)
    const [productType, setProductType] = useState(ProductTypeEnum.Standard)
    const data = useMemo(() => {
        const createAccountData = new CreateAccountBusinessProCognitoDevProps()
        createAccountData.profile.productType = productType
        createAccountData.profile.profileType = profileType
        return createAccountData
    }, [productType, profileType])
    const formData = useRef({ ...data })

    const onProfileTypeValueChanged = useCallback(
        (newProfileType: ProfileTypeEnum): void => {
            if (profileType !== newProfileType) {
                setProfileType(newProfileType)
            }
        },
        [profileType]
    )

    const onProductTypeValueChanged = useCallback(
        (newProductType: ProductTypeEnum): void => {
            if (productType !== newProductType) {
                setProductType(newProductType)
            }
        },
        [productType]
    )

    const customContent = useMemo(() => commonConfig.Content.SignUp, [commonConfig.Content.SignUp])

    const tabs = useMemo(
        () => [
            {
                title: 'General Information',
                Component: GeneralInformationForm,
                props: [{ onProfileTypeValueChanged, onProductTypeValueChanged, data: { ...formData.current } }],
            },
            {
                title: 'Personal Details',
                Component: PersonalDetailsForm,
                props: [],
            },
            {
                title: 'Acknowledgement',
                Component: AcknowledgementForm,
                props: [],
            },
        ],
        [onProfileTypeValueChanged, onProductTypeValueChanged]
    )

    return (
        <SingleCard {...customContent}>
            <CreateAccountFormIndividualStandard data={formData.current} tabs={tabs} review />
        </SingleCard>
    )
}

export default CreateAccountFormCognitoDev

import { useMemo, useRef } from 'react'
import { CreateAccountIndividualStandardCoinhubProps } from './Model'
import { SingleCard } from '../../../common'
import { useConfig } from '../../../../contexts'
import CreateAccountFormIndividualStandard from '../Form/IndividualStandard/Form'
import CustomerProfileTab from '../tabs/CustomerProfileTab'
import CustomerProfileContactInformationTab from '../tabs/CustomerProfileContactInformationTab'

const CreateAccountFormCoinhub = (): JSX.Element => {
    const { commonConfig } = useConfig()

    const data = new CreateAccountIndividualStandardCoinhubProps()
    const formData = useRef({ ...data })

    const customContent = commonConfig.Content.SignUp
    const tabs = useMemo(
        () => [
            {
                title: 'Customer Profile',
                props: [{ allowEdition: true }],
                Component: CustomerProfileTab,
            },
            {
                title: 'Contact Information',
                props: [{ allowEdition: true }],
                Component: CustomerProfileContactInformationTab,
            },
        ],
        []
    )

    return (
        <SingleCard {...customContent}>
            <CreateAccountFormIndividualStandard data={formData.current} tabs={tabs} review />
        </SingleCard>
    )
}

export default CreateAccountFormCoinhub

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { SingleCard } from '../../common'
import '../form.scss'
import './Styles.scss'
import { useCookies } from 'react-cookie'
import { useAuth } from 'contexts'
import { useLocation } from 'react-router'

const LogoutForm = (): JSX.Element => {
    const [cookies, removeCookie] = useCookies(['token'])
    const navigate = useNavigate()
    const { loading: authIsLoading, signOut } = useAuth()
    const location = useLocation()
    const redirectUri = useMemo(() => {
        const queryParams = new URLSearchParams(location.search)
        return queryParams.get('redirectUri') ?? ''
    }, [location.search])

    useEffect(() => {
        if (authIsLoading) return // do nothing if the auth is still loading
        // revoke the token if it exists and navigate to the login page
        const { token } = cookies
        if (token) {
            signOut(token)
            removeCookie('token', { path: '/' })
        }
        const signInUrl = redirectUri ? `/?redirectUri=${redirectUri}` : '/'
        navigate(signInUrl)
        // just execute when the authIsLoading changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authIsLoading])

    return (
        <SingleCard className={'logout-form'} title={'Logging Out'} description={'Please wait while we log you out'} footerText={'Thank you for using our application'}>
            <p>Login out...</p>
        </SingleCard>
    )
}

export default LogoutForm

import { useCallback, useMemo, useRef, useState } from 'react'
import Form, { TabbedItem, TabPanelOptions, Tab, GroupItem, ButtonOptions, ButtonItem, Item } from 'devextreme-react/form'
import '../../Styles.scss'
import '../../../form.scss'
import './Form.scss'
import notify from 'devextreme/ui/notify'
import { useAuth, useConfig } from '../../../../../contexts'
import { useNavigate } from 'react-router'
import { ErrorMessage, Loading } from '../../../../common'
import { ErrorMessageProps } from '../../../../common/errorMessage/Model'
import { IForm } from './IForm'
import ReCAPTCHA from 'react-google-recaptcha'

const errorMessageClear = new ErrorMessageProps()

const CreateAccountFormIndividualStandard = ({ data: formData, tabs, review = false }: IForm): JSX.Element => {
    const { commonConfig } = useConfig()
    const customCommonContent = useMemo(() => commonConfig.Content.Common, [commonConfig])
    const navigate = useNavigate()
    const { createAccount, loading } = useAuth()
    const [currentTabIndex, setCurrentTabIndex] = useState(0)
    const [errorMessage, setErrorMessage] = useState(errorMessageClear)
    const tabIndexMax = tabs.length - 1
    const nextTab = useCallback((tabIndexParam: number): void => {
        const nextTabValue = tabIndexParam + 1
        setCurrentTabIndex(nextTabValue)
    }, [])

    const lastTab = useCallback((): void => setCurrentTabIndex(tabIndexMax), [tabIndexMax])

    const [reCaptchaValue, setReCaptchaValue] = useState('')
    const reCaptchaRef = useRef(null)

    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (e: any): Promise<void> => {
            try {
                formData.business = undefined
                formData.partnerReferral = undefined
                formData.digitalAssetsDetails = undefined
                formData.wallets = undefined
                formData.accounts = undefined
                setErrorMessage(errorMessageClear)
                const response = await createAccount(formData)
                if (response.message) {
                    const cleanedMessage = response.messages.replace(/"/g, '')
                    let shortenedMessage = ''
                    if (cleanedMessage) {
                        cleanedMessage?.includes('phone_number') ? (shortenedMessage = 'Please enter a valid phone number.') : (shortenedMessage = cleanedMessage)
                        notify(shortenedMessage, 'error', 2500)
                        setErrorMessage(new ErrorMessageProps(shortenedMessage, cleanedMessage))
                    } else {
                        notify(response.message.messages.errors[0].message, 'error', 2500)
                        setErrorMessage(new ErrorMessageProps(cleanedMessage.errors[0].message, ''))
                    }
                    return
                }
                notify(
                    'Your application has been received. Please allow 1-2 business days for your information to be reviewed by our compliance team. An email will be sent with a decision and information on next steps.',
                    'success',
                    2500
                )
                navigate('/')
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                const msg = error.response?.data.error == null ? 'Create Account Error' : error.response.data.error.message
                notify(msg, 'error', 2000)
            }
        },
        [createAccount, formData, navigate]
    )

    const onTabChanged = useCallback(
        (e: { itemIndex: number }): void => {
            if (e.itemIndex !== currentTabIndex) setCurrentTabIndex(e.itemIndex)
        },
        [currentTabIndex]
    )
    return (
        <div className="individual-standard-form">
            {currentTabIndex <= tabIndexMax ? (
                <Form formData={formData} className="register" elementAttr={{ 'field-id': 'create-account-code-id' }}>
                    <TabbedItem cssClass="tabStyle">
                        <TabPanelOptions deferRendering={false} width={'100%'} selectedIndex={currentTabIndex} onItemClick={onTabChanged} />
                        {tabs.map(({ title, Component, props }, index) => (
                            <Tab key={title} title={title} disabled={currentTabIndex < index}>
                                <Component data={formData} {...formData} {...props} allowEdition nextTab={() => nextTab(currentTabIndex)} />
                            </Tab>
                        ))}
                    </TabbedItem>
                </Form>
            ) : (
                <Form className="register" formData={formData}>
                    {review && (
                        <Item editorType={'dxTextBox'} disabled={true} cssClass={'reviewDescription'}>
                            Please review the information below for accuracy. Select &apos;Register&apos; to submit your information for review or &apos;Back&apos; to update
                            details within any section.
                        </Item>
                    )}
                    {tabs.map(({ title, Component, props }, index) => (
                        <GroupItem key={index} caption={title} cssClass="outline">
                            <Component data={formData} {...formData} {...props} nextTab={() => nextTab(currentTabIndex)} allowEdition={false} />
                        </GroupItem>
                    ))}
                    <ButtonItem>
                        <ButtonOptions elementAttr={backButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true} onClick={lastTab}>
                            <span className="dx-button-text">Back</span>
                        </ButtonOptions>
                    </ButtonItem>
                    <Item horizontalAlignment="center" cssClass={`reCaptcha  ${reCaptchaValue ? 'hideItem' : ''}`}>
                        <ReCAPTCHA
                            ref={reCaptchaRef}
                            sitekey={customCommonContent.reCaptchaSiteKey}
                            onChange={(v) => {
                                if (v != null) setReCaptchaValue(v)
                            }}
                        />
                    </Item>
                    <ButtonItem cssClass={!reCaptchaValue && 'hideItem'}>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true} onClick={onSubmit}>
                            <span className="dx-button-text">{loading === true ? <Loading /> : 'Register'}</span>
                        </ButtonOptions>
                    </ButtonItem>
                    {errorMessage.message !== '' ? (
                        <Item>
                            <ErrorMessage {...errorMessage} />
                        </Item>
                    ) : null}
                </Form>
            )}
        </div>
    )
}

const submitButtonAttributes = { class: 'submit-button' }
const backButtonAttributes = { class: 'secondary-button' }

export default CreateAccountFormIndividualStandard

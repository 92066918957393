import { SingleCardCustomizableData } from '../common/single-card/Model'

export class CommonCustomizableData extends SingleCardCustomizableData {
    constructor(
        public backToLoginPrefix: string = 'Return to',
        public backToLoginLinkText: string = 'Sign In',
        public logoExtension: string = 'svg',
        public logoVersion: string = '?v=1',
        public layout: string = 'single',
        public inputType: string = 'floating',
        public reCaptchaSiteKey: string = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' // Google's test key: override the value configuring the firm content config
    ) {
        super()
    }
}

import { IProfile, Profile, ProductTypeEnum, ProfileTypeEnum, IPerson, Person, Phone, Address, PhoneTypeEnum, ICreateAccountPropsBase } from '../Model'

export interface ICreateAccountFormCoinhubProps {
    onProfileTypeChanged: (profileType: ProfileTypeEnum) => void
    onProductTypeChanged: (productType: ProductTypeEnum) => void
    data: ICreateAccountPropsBase
}

export class CreateAccountIndividualStandardCoinhubProps implements ICreateAccountPropsBase {
    constructor(
        public allowEdition: boolean = true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        public nextTab: () => void = () => {},
        public profile: IProfile = new Profile(),
        public people: IPerson[] = [new Person()]
    ) {
        this.people[0].phoneNumbers.push(new Phone(PhoneTypeEnum.Home))
        this.people[0].addresses.push(new Address())
    }

    business: undefined
    partnerReferral: undefined
    digitalAssetsDetails: undefined
    wallets: undefined
    accounts: undefined
    acknowledgement: undefined
}
